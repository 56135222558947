@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 300;
  src: local(''),
  url('static/fonts/ubuntu-v19-cyrillic/ubuntu-v19-cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('static/fonts/ubuntu-v19-cyrillic/ubuntu-v19-cyrillic-300.woff') format('woff') /* Modern Browsers */
}

@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('static/fonts/ubuntu-v19-cyrillic/ubuntu-v19-cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('static/fonts/ubuntu-v19-cyrillic/ubuntu-v19-cyrillic-regular.woff') format('woff') /* Modern Browsers */
}

@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  src: local(''),
  url('static/fonts/ubuntu-v19-cyrillic/ubuntu-v19-cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('static/fonts/ubuntu-v19-cyrillic/ubuntu-v19-cyrillic-500.woff') format('woff') /* Modern Browsers */

}

@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  src: local(''),
  url('static/fonts/ubuntu-v19-cyrillic/ubuntu-v19-cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('static/fonts/ubuntu-v19-cyrillic/ubuntu-v19-cyrillic-700.woff') format('woff') /* Modern Browsers */

}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar{
  width: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', 'Ubuntu',
    monospace;
}

